import React from 'react'
import restaurantImage from '../../assets/images/paju_brand5.jpg'
import { Jumbotron } from 'react-bootstrap'
import '../../assets/scss/index.scss'
// import PageCreator from '../../components/PageCreator'
import {Link} from 'react-router-dom'

export default function Main() {
  
    return (
        <>
        <Jumbotron style={{ margin: '0', textAlign: 'center', padding: '0'}}>
            <Link to="https://www.toasttab.com/local/order/paju-new-513-westlake-avenue-north/r-7d59294e-954a-423c-8d85-0ee6c17732cd?diningOption=takeout&rwg_token=AJKvS9V9751suXunkxd0EkOhE6P91LUci6Nhn1FwhUR0oWd3rpENNc76dJdKqUvfAN9gV9fH_KbEqZtlj0CZjb_uMZMO1R44wQ%3D%3D"></Link>
                <p className='mainFont' style={{fontSize: '0.80em', padding: '10px', color: '#777777', margin: '0'}}>Now PAJU is open for lunch and <a style={{color: '#000'}} href="https://www.toasttab.com/local/order/paju-new-513-westlake-avenue-north/r-7d59294e-954a-423c-8d85-0ee6c17732cd?diningOption=takeout&rwg_token=AJKvS9V9751suXunkxd0EkOhE6P91LUci6Nhn1FwhUR0oWd3rpENNc76dJdKqUvfAN9gV9fH_KbEqZtlj0CZjb_uMZMO1R44wQ%3D%3D" target="_blank">click here</a> to place a take out order.</p>
        </Jumbotron>
        {/* <Jumbotron style={{ margin: '0', textAlign: 'center', padding: '0'}}>
                <p className='mainFont' style={{fontSize: '0.80em', padding: '10px', color: '#777777', margin: '0'}}>Paju will be moving to a new home just one mile away in South Lake Union this summer! The last service at our current location will be Saturday, May 11.</p>
        </Jumbotron> */}
        <Jumbotron style={{paddingTop: '0', backgroundImage: `url(${restaurantImage})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '75vh'}} fluid>
        </Jumbotron>
        </>
        // <PageCreator imageName={restaurantImage}>
        //         {/* <h1 style={{fontSize: "1.65em", fontWeight: "400", color: "#ffffff"}} className="display-4 mainFont fadeUp">Welcome to Paju</h1>
        //         <p style={{fontSize: "1em", color: "#ffffff"}} className="mainFont fadeUp">Korean Cuisine</p> */}
        //         {/* <hr style={{backgroundColor: "#ffffff"}} className="fadeUp"/> */}
        // </PageCreator>
    )
}
