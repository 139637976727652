import React, { useState } from 'react'
import Layout from '../src/components/Layout'
import Main from '../src/components/Main'
import About from '../src/components/About'
import Welcome from '../src/components/Welcome'
import ThankYou from '../src/components/ThankYou'
import SignOut from '../src/components/SignOut'
import Forum from '../src/components/Forum'
import SubmitForum from '../src/components/SubmitForum'
import SubmitTextMessage from '../src/components/SubmitTextMessage'
import SubmitEmail from '../src/components/SubmitEmail'
import SubmitPayment from '../src/components/SubmitPayment'
import UserDirectory from '../src/components/UserDirectory'
import Registration from '../src/components/Registration'
import Profile   from '../src/components/Profile'
import Menu from '../src/components/Menu'
import Location from '../src/components/Location'
import Gallery from '../src/components/Gallery'
import Accolades from '../src/components/Accolades'
import Login from '../src/components/Login'
import Orders from '../src/components/Orders'
import MenuCreator from '../src/components/MenuCreator'
import CompletedOrders from '../src/components/CompletedOrders'
import Reservations from '../src/components/Reservations'
import ReservationManagement from '../src/components/Reservations/Reservation_Management'
import ThankYouReservation from '../src/components/ThankYou/reservation_thankyou'
import Store from '../src/components/Store'
import StoreCreator from '../src/components/StoreCreator'
import Special from '../src/components/Special'
import StaticMenu from '../src/components/Menu/staticMenu'
import LunchMenu from '../src/components/Menu/lunchMenu'
import DinnerMenu from '../src/components/Menu/dinnerMenu'

import { UserContext } from './UserContext'
import { OrderContext } from './OrderContext'
import { MenuContext } from './MenuContext'
import { StoreContext } from './StoreContext'

import { Router, Route, Switch} from "react-router-dom"
import history from "../src/components/History"
import UserProtectedRoute from '../src/components/UserProtectedRoutes'
import AdminProtectedRoute from '../src/components/AdminProtectedRoutes'
import NoMatch from '../src/components/NoMatch'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser as farUser } from '@fortawesome/free-regular-svg-icons'
import { faUserCog } from '@fortawesome/free-solid-svg-icons'



library.add(farUser, faUserCog)

export default function App() {
  //User Information
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  //Menu Order
  const [submittedOrder, setSubmittedOrder] = useState({})

  const [menuInfo, setMenuInfo] = useState([])
  const [storeInfo, setStoreInfo] = useState([])
  const [orderText, setOrderText] = useState(``)

  return (
    <Router history={history}>
    <UserContext.Provider value={{isLoggedIn, setLoggedIn, isAdmin, setIsAdmin, userInfo, setUserInfo}}>
    <MenuContext.Provider value={{menuInfo, setMenuInfo}}>
    <StoreContext.Provider value={{storeInfo, setStoreInfo}}>
    <OrderContext.Provider value={{orderText, setOrderText, submittedOrder, setSubmittedOrder}}>  
    
    <Layout>
    <Switch>
      
          <Route exact path="/" component={Main} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Menu" component={DinnerMenu} />
          <Route exact path="/LunchMenu" component={LunchMenu} />
          <Route exact path="/DinnerMenu" component={DinnerMenu} />
          <Route exact path="/Gallery" component={Gallery} />
          <Route exact path="/Location" component={Location} />
          <Route exact path="/Registration" component={Registration}/>
          <Route exact path="/SignOut" component={SignOut} />
          <Route exact path="/Accolades" component={Accolades} />
          <Route exact path="/Forum" component={Forum} />
          <Route exact path="/SubmitPayment" component={SubmitPayment} />
          <Route exact path="/ThankYou" component={ThankYou} />
          {/* <Route exact path="/Reservations" component={Reservations} /> */}
          <Route exact path="/Store" component={Store} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/ThankYouReservation" component={ThankYouReservation} />
          <Route exact path="/Special" component={Special} />

          <Route path='/PopUp' component={() => { 
          window.location.href = 'https://www.experiencehedonisme.com/popup-paju2'; 
          return null;
          }}/>

          <Route path='/Reservations' component={() => {
          window.open('https://www.exploretock.com/paju', '_blank'); 
          return null;
          }}/>

          <UserProtectedRoute exact path="/Welcome" component={Welcome} />
          <AdminProtectedRoute exact path="/SubmitForum" component={SubmitForum} />
          <AdminProtectedRoute exact path="/Orders" component={Orders} />
          <AdminProtectedRoute exact path="/SubmitTextMessage" component={SubmitTextMessage} />
          <AdminProtectedRoute exact path="/SubmitEmail" component={SubmitEmail} />
          <AdminProtectedRoute exact path="/UserDirectory" component={UserDirectory} />
          <AdminProtectedRoute exact path="/MenuCreator" component={MenuCreator} />
          <AdminProtectedRoute exact path="/CompletedOrders" component={CompletedOrders} />
          <AdminProtectedRoute exact path="/ReservationManagement" component={ReservationManagement} />
          <AdminProtectedRoute exact path="/StoreCreator" component={StoreCreator} />
          
         
          <UserProtectedRoute exact path="/Profile" component={Profile} />
          <Route component={NoMatch} />
          
    </Switch>
    </Layout>
 
    </OrderContext.Provider>
    </StoreContext.Provider>    
    </MenuContext.Provider>
    </UserContext.Provider>
    </Router>
  )
}
