import React, { useState, useContext, useEffect} from 'react'
import PageCreator from '../PageCreator'
import bibiImage from '../../assets/images/bibi.png'
import { Button, Form, Row, Col, InputGroup, FormControl, Image, Modal, Container} from 'react-bootstrap'
import '../../assets/scss/index.scss'
import { OrderContext } from '../../OrderContext';
import { MenuContext } from '../../MenuContext';
import history from '../History';
import API from '../../utils/API';
import { UserContext } from '../../UserContext';
import socketIOClient from 'socket.io-client';


export default function () {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { isAdmin } = useContext(UserContext);
    const {menuInfo, setMenuInfo} = useContext(MenuContext);
    const {submittedOrder, setSubmittedOrder} = useContext(OrderContext);
    
    const endpoint = "http://localhost:3001";
    const socket = socketIOClient(endpoint);

    const [order, setOrder] = useState({});

    function getMenuInfo(){
        API.getMenu().then(  
            function(response){
            setMenuInfo(response.data)  
          })        
    }

    const increaseMenuItemCount = event => {
        event.preventDefault();

        const targetID = event.target.id
        const targetName = event.target.name
        const targetPrice = event.target.value
        const targetDescription = event.target.getAttribute('description')

        //initialize object into order
        if(isNaN(order[targetName])){
        
            setOrder(prevState => ({...prevState, [targetName]:0}))
            setSubmittedOrder(prevState =>({...prevState, [targetID]:{title:targetName, quantity:0, cost:targetPrice, description:targetDescription, id:[targetID]}}))
        }
        setOrder(prevState => ({...prevState, [targetName]: prevState[targetName] + 1}))
        setSubmittedOrder(prevState =>({...prevState, [targetID]:{title:targetName, quantity: prevState[targetID].quantity + 1, cost:targetPrice, description:targetDescription, id:[targetID]}}))
    }

    const decreaseMenuItemCount = event => {
        event.preventDefault();
        const targetID = event.target.id
        const targetName = event.target.name
        const targetPrice = event.target.value
        const targetDescription = event.target.getAttribute('description')

        if(order[targetName] !== 0 && isNaN(order[targetName]) !== true){
        setOrder(prevState => ({...prevState, [targetName]: prevState[targetName] - 1}))
        setSubmittedOrder(prevState =>({...prevState, [targetID]:{title:targetName, quantity: prevState[targetID].quantity - 1, cost:targetPrice, description:targetDescription}}))
        }
    }

    const deleteMenuItem = (id) => {
        API.deleteMenuItem(id)
        .then(
            API.getMenu().then(  
                function(response){
                setMenuInfo(response.data)  
                history.push('/Menu')
            })
        )
    }

    const submitOrder = event =>{
        event.preventDefault();   
        history.push('/SubmitPayment')

    }

    socket.on('menuChangeEvent', async () =>{
        try{
        await API.getMenu().then(
                function (response) {
                setMenuInfo(response.data)
                })
        }
        catch(error){
            console.log('THIS IS THE ERROR!', error)
        }
    })
    
    useEffect(() => {
        getMenuInfo()
       
    },[]);

    return (
        <PageCreator imageName={bibiImage} opacityLevel="0.9">
            <Form>
                <h1 className='mainFont'>Lunch Menu</h1>
                <hr style={{ backgroundColor: '222222' }} />
                <Row>
                <Col lg={10} md={9} sm={6} xs={6}>
                {menuInfo.map(item => (
                        <>
                        {item?.menuCategory === 'Main' && item?.menuType === 'Lunch' ? 
                    
                        <div  key = {item._id} id={item._id}>
                            <p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p>
                            <p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p>
                            <hr/>
                        </div>
                        
                        : ''} 
                      
                        {isAdmin && item?.menuCategory === 'Main' ?
                        
                        <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        
                        : ''
                        }
                        </>
                        
                        ))}
                        </Col>
                </Row>

                {menuInfo.filter(item => item?.menuCategory === 'Dessert').length > 0 ? '':
                    <>
                    <h2 className='mainFont'>Dessert</h2>
                    <hr style={{ backgroundColor: '222222' }} /><br/>
                   
                    <Row>
                    {menuInfo.map(item => (
                        <>
                          <Col lg={10} md={9} sm={6} xs={6}>
                            {item?.menuCategory === 'Dessert' && item?.menuType === 'Lunch' ? 
                            <div><p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p><p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p></div>:''}
                        </Col>
                        {isAdmin && item?.menuCategory === 'Dessert' ?
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        </Col>
                        : ''
                        }
                        </>

                    ))}
                   </Row>
                    <br />
                    </>
                    }
                    <h2 className='mainFont'>Non-Alcoholic Beverage</h2>
                    <hr style={{ backgroundColor: '222222' }} /><br/>
                    <Row>
            
                    {menuInfo.map(item => (
                        <>
                          <Col lg={10} md={9} sm={6} xs={6}>
                            {item?.menuCategory === 'NonAlcoholicBeverage' && item?.menuType === 'Lunch' ? 
                            <div><p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p><p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p></div>:''}
                        </Col>
                        {isAdmin && item?.menuCategory === 'NonAlcoholicBeverage' ?
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        </Col>
                        : ''
                        }
                        </>

                    ))}
                    </Row>
                    {/* <h2 className='mainFont'>Wine By the Bottle</h2> */}
                    {/* <hr style={{ backgroundColor: '222222' }} /><br/> */}

                    {menuInfo.filter(item => item?.menuCategory === 'WineByTheBottleBubbles').length > 0 ? '':
                        <>
                            <h3 className='mainFont'>Bubbles</h3>
                            <hr style={{ backgroundColor: '222222' }} /><br/>
                            <Row>
                            {menuInfo.map(item => (
                                item?.menuCategory === 'WineByTheBottleBubbles' && (
                                    <>
                                    <Col lg={10} md={9} sm={6} xs={6}>
                                        {item?.menuType === 'Lunch' && 
                                        <div>
                                            <p className='mainFont' style={{ marginBottom: '0px' }}>
                                                {item.menuName}.....{item.menuItemPrice}
                                            </p>
                                            <p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p>
                                        </div>}
                                    </Col>
                                    {isAdmin && (
                                        <Col lg={2} md={3} sm={6} xs={6}>
                                            <Button 
                                                id={item._id} 
                                                onClick={() => deleteMenuItem(item._id)} 
                                                variant="outline-danger"
                                            >
                                                delete menu item
                                            </Button>
                                        </Col>
                                    )}
                                    </>
                                )
                            ))}
                            </Row>
                        </>
                    }
                    <br />
                    {menuInfo.filter(item => item?.menuCategory === 'WineByTheBottleWhite').length > 0 ? '':
                    <>
                    <h3 className='mainFont'>White</h3>
                    <hr style={{ backgroundColor: '222222' }} /><br/>
                    <Row>
                    {menuInfo.map(item => (
                        <>
                          <Col lg={10} md={9} sm={6} xs={6}>
                            {item?.menuCategory === 'WineByTheBottleWhite' && item?.menuType === 'Lunch' ? 
                            <div><p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p><p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p></div>:''}
                        </Col>
                        {isAdmin && item?.menuCategory === 'WineByTheBottleWhite' ?
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        </Col>
                        : ''
                        }
                        </>

                    ))}
                    </Row>
                    <br />
                    </>
                    }
                    {menuInfo.filter(item => item?.menuCategory === 'WineByTheBottleRed').length > 0 ? '':
                    <>
                    <h3 className='mainFont'>Red</h3>
                    <hr style={{ backgroundColor: '222222' }} /><br/>
                    <Row>
                    {menuInfo.map(item => (
                        <>
                          <Col lg={10} md={9} sm={6} xs={6}>
                            {item?.menuCategory === 'WineByTheBottleRed' && item?.menuType === 'Lunch' ? 
                            <div><p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p><p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p></div>:''}
                        </Col>
                        {isAdmin && item?.menuCategory === 'WineByTheBottleRed' ?
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        </Col>
                        : ''
                        }
                        </>

                    ))}
                    </Row>
                    <br />
                    </>
                    }
                    {menuInfo.filter(item => item?.menuCategory === 'WineByTheGlass').length > 0 ? '':
                    <>
                    <h3 className='mainFont'>Wine By the Glass</h3>
                    <hr style={{ backgroundColor: '222222' }} /><br/>
                    <Row>
                    {menuInfo.map(item => (
                        <>
                          <Col lg={10} md={9} sm={6} xs={6}>
                            {item?.menuCategory === 'WineByTheGlass' && item?.menuType === 'Lunch' ? 
                            <div><p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p><p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p></div>:''}
                        </Col>
                        {isAdmin && item?.menuCategory === 'WineByTheGlass' ?
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        </Col>
                        : ''
                        }
                        </>

                    ))}
                    </Row>
                    <br />
                    </>
                    }
                    <h3 className='mainFont'>Beer and Rice Wine</h3>
                    <hr style={{ backgroundColor: '222222' }} /><br/>
                    <Row>
                    {menuInfo.map(item => (
                        <>
                          <Col lg={10} md={9} sm={6} xs={6}>
                            {item?.menuCategory === 'BeerAndRiceWine' && item?.menuType === 'Lunch' ? 
                            <div><p className='mainFont' style={{ marginBottom: '0px' }}>{item.menuName}.....{item.menuItemPrice}</p><p style={{ fontSize: '0.85em' }}>{item.menuDescription}</p></div>:''}
                        </Col>
                        {isAdmin && item?.menuCategory === 'BeerAndRiceWine' ?
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <Button id={item._id} onClick={()=> deleteMenuItem(item._id)} variant="outline-danger">delete menu item</Button>
                        </Col>
                        : ''
                        }
                        </>

                    ))}
                    </Row>
                    <br />

            </Form>
        </PageCreator>
    )
}
