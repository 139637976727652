import React from 'react'
import aboutImage from '../../assets/images/about_paju.jpg'
import PageCreator from '../../components/PageCreator'
import '../../assets/scss/index.scss'

export default function index() {
    return (
        <PageCreator imageName={aboutImage} opacityLevel='0.85'>
            <p className='mainFont' style = {{color: "#222222", fontSize: '1.5em', margin:'0'}}>Paju's Story</p> 

            <hr style={{backgroundColor: "#222222"}} />
            <p className='mainFont' style={{color: "#222222"}}>**Paju**, located in Seattle, is a celebrated dining destination known for its innovative Korean cuisine. The restaurant, named after the city of Paju in South Korea, has quickly become a favorite among locals and visitors alike for its unique blend of traditional Korean flavors and contemporary culinary techniques. Since its opening, Paju has garnered praise for its exceptional dishes that pay homage to Korean heritage while incorporating fresh, locally-sourced ingredients from the Pacific Northwest.
            <br /><br/>
            The ambiance at Paju is warm and inviting, with a modern decor punctuated by sweeping ceilings, satiny finishes, and a river rock encased wood-fired hearth. The food takes center stage in a menu that is a carefully curated selection of small plates and larger entrees, designed to be shared and enjoyed communally. Signature dishes such as Paju Fried Rice with squid ink, Yellowtail in Naengmyeon broth, Bugak with oxtail and cucumber kimchi and Mushroom with white kimchi have become iconic, showcasing the rich and complex flavors of innovative Korean cuisine.            </p>
        </PageCreator>
    )
}
