import React, {useState} from 'react'
import PageCreator from '../../components/PageCreator'
import menuCreatorImage from '../../assets/images/menuCreator.jpg'
import { Form, Button } from 'react-bootstrap'
import '../../assets/scss/index.scss'
import API from '../../utils/API'
import history from '../History'
export default function MenuCreator() {
    const [menuName, setMenuName] = useState('')
    const [menuDescription, setMenuDescription] = useState('')
    const [menuItemPrice, setMenuItemPrice] = useState('')
    const [menuImageURL, setMenuImageURL] = useState('')
    const [menuPriority, setMenuPriority] = useState('')
    const [menuCategory, setMenuCategory] = useState('Main')
    const [menuType, setMenuType] = useState('Lunch')

   
    const createMenuItem = event => {
        event.preventDefault();
        API.submitMenuItem({
            menuName: menuName,
            menuDescription: menuDescription,
            menuItemPrice: menuItemPrice,
            menuCategory: menuCategory,
            menuPriority: menuPriority,
            menuType: menuType,
        }).then(
            history.push('/Menu'),
            setMenuName(''),
            setMenuDescription(''),
            setMenuItemPrice(''),
            setMenuCategory(''),
            setMenuPriority(''),
            setMenuType('')
        )

    }
    return (
        <PageCreator imageName={menuCreatorImage} opacityLevel='0.9'>
            <p className='mainFont' style={{ fontSize: '1.2em' }}>Menu Creator</p>
            <hr />
            <Form>
                <Form.Group controlId="title">
                    <Form.Label>Menu Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter Menu Name" onChange={event => setMenuName(event.target.value)}/>
                    <Form.Text className="text-muted">
                </Form.Text>
                </Form.Group>
                <Form.Group controlId="menuDescription">
                    <Form.Label>Menu Description</Form.Label>
                    <Form.Control required type="text" placeholder="Menu Description" onChange={event => setMenuDescription(event.target.value)}/>
                </Form.Group>
                <Form.Group controlId="menuPrice">
                    <Form.Label>Menu Item Price</Form.Label>
                    <Form.Control required type="number" placeholder="Menu Item Price" onChange={event => setMenuItemPrice(event.target.value)}/>
                </Form.Group>
                <Form.Group controlId="formGridState">
                <Form.Label>Menu Category</Form.Label>
                    <Form.Control as="select" defaultValue="Choose..." onChange={event=>setMenuCategory(event.target.value)}>
                        <option value="Main">Main</option>
                        <option value ="Dessert">Dessert</option>
                        <option value ="WineByTheGlass">Wine By the Glass</option>
                        <option value ="BeerAndRiceWine">Beer & Rice Wine</option>
                        <option value ="NonAlcoholicBeverage">Non-Alcoholic Beverages</option>
                        <option value ="WineByTheBottleBubbles">Wine By the Bottle Bubbles</option>
                        <option value ="WineByTheBottleWhite">Wine By the Bottle White</option>
                        <option value ="WineByTheBottleRed">Wine By the Bottle Red</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formGridState">
                <Form.Label>Menu Type</Form.Label>
                    <Form.Control as="select" defaultValue="Choose..." onChange={event=>setMenuType(event.target.value)}>
                        <option value="Lunch">Lunch</option>
                        <option value ="Dinner">Dinner</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formGridState">
                <Form.Label>Menu Priority</Form.Label>
                    <Form.Control type="number" placeholder="Menu Priority Number 1-100" defaultValue="" onChange={event=>setMenuPriority(event.target.value)}>

                    </Form.Control>
                </Form.Group>
                    {/* <Form.Label>Menu Category</Form.Label>
                    <Form.Control required type="text" placeholder="Menu Description" onChange={event => setMenuCategory(event.target.value)}/> */}
                 
                {/* <Form.Group controlId="menuCategory">
                    <Form.Label>Menu Category</Form.Label>
                    <Form.Select defaultValue="Choose...">
                        <option>Choose...</option>
                        <option>...</option>
                    </Form.Select>
                </Form.Group> */}
                {/* <Form.Group controlId="menuImageURL">
                    <Form.Label>Menu Image URL</Form.Label>
                    <Form.Control required type="text" placeholder="Image URL" onChange={event => setMenuImageURL(event.target.value)}/>
                </Form.Group> */}
            
                <Button style = {{float: 'right'}} variant="outline-dark" type="submit" onClick={createMenuItem}>
                    Add to Menu
                </Button>
                <br />
                <br />
            </Form>

        </PageCreator>
    )
}
